import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideo from 'components/Outdoor_Cameras/IN-9008_HD/Lense_Adjustment/Video9008Lense';
import NavButtons from 'components/Outdoor_Cameras/IN-9008_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9008 Full HD Manual Lense Adjustment",
  "path": "/Outdoor_Cameras/IN-9008_HD/Lense_Adjustment/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-9008 Full HD Indoor IP Camera Manual Lense Adjustment",
  "image": "./P_SearchThumb_IN-9008HD_Lense_Adjust.png",
  "social": "/images/Search/P_SearchThumb_IN-9008HD_Lense_Adjust.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9008HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IN-9008 Full HD Manual Lense Adjustment' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-9008 Full HD Indoor IP Camera Manual Lense Adjustment' image='/images/Search/P_SearchThumb_IN-9008HD_Lense_Adjust.png' twitter='/images/Search/P_SearchThumb_IN-9008HD_Lense_Adjust.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-9008_HD/Objektivjustage/' locationFR='/fr/Outdoor_Cameras/IN-9008_HD/Lense_Adjustment/' crumbLabel="Lense" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-9008-full-hd-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-9008-full-hd-manual",
        "aria-label": "in 9008 full hd manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-9008 Full HD Manual`}</h2>
    <h3 {...{
      "id": "lense-adjustment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#lense-adjustment",
        "aria-label": "lense adjustment permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lense Adjustment`}</h3>
    <YoutubeVideo mdxType="YoutubeVideo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-9008 Full HD standard lense has a depth of field (also focus range) that is adjusted to a distance of approx. 1 - 8m. In case your area of interest is out of focus, you will have to adjust the lense accordingly.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c1a574dc3c789e8358ccdbf0c8999c08/34e70/IN-9008_Lense_Adjust_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+0lEQVQY03WQy27CMBBF8///Bu2qqlq6KESxTfyKPY7HHj+qgJBaCmd5pXNHc4dW6xpCjLGUgojpAqYUlmVhXBuzGkuIpVYicsvCGWMT44xppQcAGE+nr8PBGpNz7hdKb2GWcv/qQph2+/n7GBHjujrntNbLhRDCYK3lnCMiAPQbOWdEVEoJziUX4F1/xCCEGMdxmiZr7W85Is5SCs61tT6Ea95a+yODh3mez+LsnENEImqtldaCUubt3ccodi9Ryqt6f3n7kKjVjZxzKYVyNtay41F8fEqtPBfttsW93P719d4RsdYKYZs8pdSfMDxMiSilBN4DQCF6Jv8A4L3PfVTYiyEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c1a574dc3c789e8358ccdbf0c8999c08/e4706/IN-9008_Lense_Adjust_01.avif 230w", "/en/static/c1a574dc3c789e8358ccdbf0c8999c08/d1af7/IN-9008_Lense_Adjust_01.avif 460w", "/en/static/c1a574dc3c789e8358ccdbf0c8999c08/7f308/IN-9008_Lense_Adjust_01.avif 920w", "/en/static/c1a574dc3c789e8358ccdbf0c8999c08/e11fd/IN-9008_Lense_Adjust_01.avif 1053w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c1a574dc3c789e8358ccdbf0c8999c08/a0b58/IN-9008_Lense_Adjust_01.webp 230w", "/en/static/c1a574dc3c789e8358ccdbf0c8999c08/bc10c/IN-9008_Lense_Adjust_01.webp 460w", "/en/static/c1a574dc3c789e8358ccdbf0c8999c08/966d8/IN-9008_Lense_Adjust_01.webp 920w", "/en/static/c1a574dc3c789e8358ccdbf0c8999c08/0528c/IN-9008_Lense_Adjust_01.webp 1053w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c1a574dc3c789e8358ccdbf0c8999c08/81c8e/IN-9008_Lense_Adjust_01.png 230w", "/en/static/c1a574dc3c789e8358ccdbf0c8999c08/08a84/IN-9008_Lense_Adjust_01.png 460w", "/en/static/c1a574dc3c789e8358ccdbf0c8999c08/c0255/IN-9008_Lense_Adjust_01.png 920w", "/en/static/c1a574dc3c789e8358ccdbf0c8999c08/34e70/IN-9008_Lense_Adjust_01.png 1053w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c1a574dc3c789e8358ccdbf0c8999c08/c0255/IN-9008_Lense_Adjust_01.png",
              "alt": "IN-9008 Full HD Manual Objektivjustage",
              "title": "IN-9008 Full HD Manual Objektivjustage",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`I.`}</strong>{` Slide to top cover towards the front to take it off and loosen the four screws at the front cover of the camera.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2175ef8ffef972316db2218bb0100fff/d53ff/IN-9008_Lense_Adjust_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAABLElEQVQY032QPU/DMBCG83thQuwsjGz8GyQG1IE/gBCICtGkTUJa101ixx/34UNx1Qox8EwnvXf23VNAjIgoJ5g5xoiAM4AIcwUQAUBEUkoAEGOUlESkGIeh3tTTNIkIEcUww0Sc5pxz0zCOVVnGEIgJEOuuY+Z5+ND3bds65yY7xRiD90Qk+SWs1lhtZHL9TpVlqbVG7/339+fTwr28CkChMwAwWcvMwXvMG1I/bC+v9d29Xzyr94+6bZXWqPb7h8e3m9vtxRWu60IpVZVl0zSE6EOYh88KEFOutT6svlZaa07JE1VtQ/mDwlprjT3ecPThnIOsBRFDCM7abdctl0tjTGKejFG73SwspSJl5BfphIhkzcBMwXszjsf03FnIvzCRz4eYcTTG/El/ALrnywdpsAhaAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2175ef8ffef972316db2218bb0100fff/e4706/IN-9008_Lense_Adjust_02.avif 230w", "/en/static/2175ef8ffef972316db2218bb0100fff/d1af7/IN-9008_Lense_Adjust_02.avif 460w", "/en/static/2175ef8ffef972316db2218bb0100fff/7f308/IN-9008_Lense_Adjust_02.avif 920w", "/en/static/2175ef8ffef972316db2218bb0100fff/ca993/IN-9008_Lense_Adjust_02.avif 1068w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2175ef8ffef972316db2218bb0100fff/a0b58/IN-9008_Lense_Adjust_02.webp 230w", "/en/static/2175ef8ffef972316db2218bb0100fff/bc10c/IN-9008_Lense_Adjust_02.webp 460w", "/en/static/2175ef8ffef972316db2218bb0100fff/966d8/IN-9008_Lense_Adjust_02.webp 920w", "/en/static/2175ef8ffef972316db2218bb0100fff/55b8e/IN-9008_Lense_Adjust_02.webp 1068w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2175ef8ffef972316db2218bb0100fff/81c8e/IN-9008_Lense_Adjust_02.png 230w", "/en/static/2175ef8ffef972316db2218bb0100fff/08a84/IN-9008_Lense_Adjust_02.png 460w", "/en/static/2175ef8ffef972316db2218bb0100fff/c0255/IN-9008_Lense_Adjust_02.png 920w", "/en/static/2175ef8ffef972316db2218bb0100fff/d53ff/IN-9008_Lense_Adjust_02.png 1068w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2175ef8ffef972316db2218bb0100fff/c0255/IN-9008_Lense_Adjust_02.png",
              "alt": "IN-9008 Full HD Manual Objektivjustage",
              "title": "IN-9008 Full HD Manual Objektivjustage",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`II.`}</strong>{` Carefully remove the front cover from the main casing and put it aside.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8bd5ee71e92e4d293bdf3929ea00676e/8ea22/IN-9008_Lense_Adjust_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAABK0lEQVQoz22RTU4EIRBG+/6HcOEtTNSNiTHODUx043RPK0M1UPwU0AVmQNvRzNuR4lUVH0OtNcaolXbOpZRiI8WotQaABWBZlpTSekYppTYGItJaCyGklKXR2wGAsxYAQAgF4IlijDnl3v5btojWWudczrnW2uXg/QKLS/EIUn58mqcdE23VvshJ1korpRCRQtjK3jmptbq5e33evez389U1vr6dqnyqMnMfPiAiSJBHicZssrPWEKmHx/fb+1lKux9jm7yRUmLmoZQagqcQbAss58zMKSUhBHovx2kcR090nlOnlDKcn3nlfNJzW0cexmme58M0oTHrusaWGa+83f8jbxARMzvn0BhrbWhx9NfmnH+/6qKcf/Dexxg3+R+X5e477xHRIjLzRfkLBgdFE5E1PekAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8bd5ee71e92e4d293bdf3929ea00676e/e4706/IN-9008_Lense_Adjust_03.avif 230w", "/en/static/8bd5ee71e92e4d293bdf3929ea00676e/d1af7/IN-9008_Lense_Adjust_03.avif 460w", "/en/static/8bd5ee71e92e4d293bdf3929ea00676e/7f308/IN-9008_Lense_Adjust_03.avif 920w", "/en/static/8bd5ee71e92e4d293bdf3929ea00676e/fab00/IN-9008_Lense_Adjust_03.avif 1023w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8bd5ee71e92e4d293bdf3929ea00676e/a0b58/IN-9008_Lense_Adjust_03.webp 230w", "/en/static/8bd5ee71e92e4d293bdf3929ea00676e/bc10c/IN-9008_Lense_Adjust_03.webp 460w", "/en/static/8bd5ee71e92e4d293bdf3929ea00676e/966d8/IN-9008_Lense_Adjust_03.webp 920w", "/en/static/8bd5ee71e92e4d293bdf3929ea00676e/da35d/IN-9008_Lense_Adjust_03.webp 1023w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8bd5ee71e92e4d293bdf3929ea00676e/81c8e/IN-9008_Lense_Adjust_03.png 230w", "/en/static/8bd5ee71e92e4d293bdf3929ea00676e/08a84/IN-9008_Lense_Adjust_03.png 460w", "/en/static/8bd5ee71e92e4d293bdf3929ea00676e/c0255/IN-9008_Lense_Adjust_03.png 920w", "/en/static/8bd5ee71e92e4d293bdf3929ea00676e/8ea22/IN-9008_Lense_Adjust_03.png 1023w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8bd5ee71e92e4d293bdf3929ea00676e/c0255/IN-9008_Lense_Adjust_03.png",
              "alt": "IN-9008 Full HD Manual Objektivjustage",
              "title": "IN-9008 Full HD Manual Objektivjustage",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`III.`}</strong>{` Open the WebUI and check the live video. Turn the lense, until your area of interest is in focus. If you turn the lense clockwise, the plane of focus moves away from the camera image sensor.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9e54631aaee66a37ba0462b830f8faed/0f7d5/IN-9008_Lense_Adjust_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRElEQVQoz32RYU/DIBCG+///hDHGf2LUxPhhWeJHt2XdKi1QCi0HxwHG4mqzmL3f4O7hXt6rcs4ppRhjzjmuRQQAzjlEzLPKdUopX1RRIKMNY0wKiYj+IqUGwblU6osxwbm1FpxbquWJyswSnBtjioucMxFJIZBIH45GiNE5H2MIoTQQkXPuF9ZaAwARLbAHkOMo397ru4dj1/afu2m3T3O5GPbeE1ElhFBKSSkB4A92jhszbLbN08tHXTfPr939I61gIvLeV2oW51xrvcCI2EsJiIMx9fncb7bT/rCeXHqqlBIAeO/B2hJGnL/X970QopfyfDq1TeMRiWgd9c+f14eyDES00yS4aFnbNA1jrOs6IkLEsrmy12t4bSmE4JwbhkFrPU3jEmeYdQsucyKRtTalpLW+MnwLzjmHELzzJU5r7b8936mFftGMFzHpAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e54631aaee66a37ba0462b830f8faed/e4706/IN-9008_Lense_Adjust_04.avif 230w", "/en/static/9e54631aaee66a37ba0462b830f8faed/d1af7/IN-9008_Lense_Adjust_04.avif 460w", "/en/static/9e54631aaee66a37ba0462b830f8faed/7f308/IN-9008_Lense_Adjust_04.avif 920w", "/en/static/9e54631aaee66a37ba0462b830f8faed/54e5b/IN-9008_Lense_Adjust_04.avif 993w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9e54631aaee66a37ba0462b830f8faed/a0b58/IN-9008_Lense_Adjust_04.webp 230w", "/en/static/9e54631aaee66a37ba0462b830f8faed/bc10c/IN-9008_Lense_Adjust_04.webp 460w", "/en/static/9e54631aaee66a37ba0462b830f8faed/966d8/IN-9008_Lense_Adjust_04.webp 920w", "/en/static/9e54631aaee66a37ba0462b830f8faed/13f34/IN-9008_Lense_Adjust_04.webp 993w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e54631aaee66a37ba0462b830f8faed/81c8e/IN-9008_Lense_Adjust_04.png 230w", "/en/static/9e54631aaee66a37ba0462b830f8faed/08a84/IN-9008_Lense_Adjust_04.png 460w", "/en/static/9e54631aaee66a37ba0462b830f8faed/c0255/IN-9008_Lense_Adjust_04.png 920w", "/en/static/9e54631aaee66a37ba0462b830f8faed/0f7d5/IN-9008_Lense_Adjust_04.png 993w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9e54631aaee66a37ba0462b830f8faed/c0255/IN-9008_Lense_Adjust_04.png",
              "alt": "IN-9008 Full HD Manual Objektivjustage",
              "title": "IN-9008 Full HD Manual Objektivjustage",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`IV.`}</strong>{` If you turn the lense counter clockwise, the plane of focus moves towards the image sensor.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/04f5c1cada1347505c1f7d54c26fea73/1be7e/IN-9008_Lense_Adjust_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9UlEQVQY03XOwW7DIAwG4Lz/u01VtcPUVb20K4UEAg4BQ8BmSrYepqXf8bd+211jTrgiovyUcg7ee9WP44jTVFKqG++9lFI+VkbrLsYo7vfz59kYk1Jqm8qM1g5vh2mC++GoLpeYUowRALTWAGCt9d53APAQAhFjiET0W64VEbUxUqlByglgTZnbX53W+na9CiGcdfwc01bu+15JZaz1IbQ969vDMCilAAARSynMTMzRwfhxmkNQx/cgHvuXf54kImZelqXWuiyLA5C3L3U6D1pPShHi/mX+t6+1llIiohCjcy7n3F7odtNSas45zLP3vpbyqvwN2M/Pbm7Tay0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/04f5c1cada1347505c1f7d54c26fea73/e4706/IN-9008_Lense_Adjust_05.avif 230w", "/en/static/04f5c1cada1347505c1f7d54c26fea73/d1af7/IN-9008_Lense_Adjust_05.avif 460w", "/en/static/04f5c1cada1347505c1f7d54c26fea73/7f308/IN-9008_Lense_Adjust_05.avif 920w", "/en/static/04f5c1cada1347505c1f7d54c26fea73/0e073/IN-9008_Lense_Adjust_05.avif 1059w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/04f5c1cada1347505c1f7d54c26fea73/a0b58/IN-9008_Lense_Adjust_05.webp 230w", "/en/static/04f5c1cada1347505c1f7d54c26fea73/bc10c/IN-9008_Lense_Adjust_05.webp 460w", "/en/static/04f5c1cada1347505c1f7d54c26fea73/966d8/IN-9008_Lense_Adjust_05.webp 920w", "/en/static/04f5c1cada1347505c1f7d54c26fea73/11814/IN-9008_Lense_Adjust_05.webp 1059w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/04f5c1cada1347505c1f7d54c26fea73/81c8e/IN-9008_Lense_Adjust_05.png 230w", "/en/static/04f5c1cada1347505c1f7d54c26fea73/08a84/IN-9008_Lense_Adjust_05.png 460w", "/en/static/04f5c1cada1347505c1f7d54c26fea73/c0255/IN-9008_Lense_Adjust_05.png 920w", "/en/static/04f5c1cada1347505c1f7d54c26fea73/1be7e/IN-9008_Lense_Adjust_05.png 1059w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/04f5c1cada1347505c1f7d54c26fea73/c0255/IN-9008_Lense_Adjust_05.png",
              "alt": "IN-9008 Full HD Manual Objektivjustage",
              "title": "IN-9008 Full HD Manual Objektivjustage",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`V.`}</strong>{` After you adjust the focal point, carefully reinstall the front cover to the casing and tighten the screws again. Also slide back the top cover.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      